.App-Window {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  background-color: #F0F8FF;
}

.App-Title {
  background-color: #F0F8FF;
}

.App-Butons {
  background-color: #F0F8FF;
  font-variant: "outline-secondary";
  text-align: center;
  display: flex;
  grid-gap: 20px;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}

.App-Logo {
  text-align: center;
  margin-top: 80px;
}

.App-Button {
  color:"primary"
}

.Pruebas {
  display: block;
  width:30px;
  height:30px;
  background-color:#577459;
  margin: 5px;
  padding:10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size:11px;
  line-height:32px;
  text-transform: uppercase;
  float:left;
}
.Pruebas:hover{
  opacity: 0.50;
  -moz-opacity: .50;
  filter:alpha (opacity=50);
}
.Pruebas a{
  color:#fff;
  text-decoration:none;
  padding:5px 5px 5px 0;
}

.boton{
  display: block;
  text-align: center;
  width:50px;
  height:50px;
  background-color:#0F059F;
  margin: 5px;
  padding:10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size:11px;
  line-height:32px;
  text-transform: uppercase;
  float:left;
  border: 1px solid #0f0f0f;
}
.boton:hover{
  opacity: 0.50;
  -moz-opacity: .50;
  filter:alpha (opacity=50);
}

.boton a{
  color:#fff;
  text-decoration:none;
  padding:5px 5px 5px 0;
}

.cuadrado2 {
  width: 350px; 
  height: 60px; 
  border: 1.5px solid #0b0404;  
  display: flex;
  background: #fff;
  border-radius: 8px;
}

.pos-text {
  text-align: left;
}

.pos-item {
  margin-right: 50%;
}

.box-one {
  display: flex;
  width: 225px; 
  height: 60px;
  justify-content: center;
  flex-direction: column-reverse;
  text-align: left;
  font-size: 22px;
  margin-left: 30px; 
}

.box-two {
  display: flex;
  width: 75px; 
  height: 55px;
  flex-direction: row;
  justify-content: flex-end;
}